<template>
  <div class="top-floating-item">
    <span>
      <div class="date">
        <span>5</span>
        <p>ნოემბერი</p>
      </div>
    </span>
  </div>
  <div class="eb-container">
    <div class="series-image-item">
      <span> </span>
    </div>
    <div class="election-container">
      <div class="election-tour">
        <div class="election-info-box">
          <div class="left-side">
            <div class="col-item col1">
              <div class="nmb">5</div>
              <span> ნოემბერი </span>
            </div>
            <div class="col-item col2">
              <ul class="stages-ul">
                <li>შესარჩევი</li>
                <li>ფინალი</li>
                <li>ჩატენვების შოუ</li>
                <li>MVP</li>
              </ul>
              <p>
                <span>
                  <span class="hide-mobile">ლოკაცია:</span>
                  <a href="https://maps.app.goo.gl/ECdLCF8M8AMQYJmv5" target="_blank"
                    ><span><img class="locationIcon" :src="require('@/assets/img/locationIcon.png')"/></span> <span class="address">დიდუბე: რ. გვენეტაძის ქუჩა</span></a
                  >
                </span>
                <br />
                დრო: <span class="time">14:00 - 20:00</span>
              </p>
            </div>
          </div>

          <!-- <div class="learn-more show-mobile">
            <a href="https://play.fiba3x3.com/events/55aaec26-60ba-4405-b149-ba3d4719f5f6?fbclid=IwAR322QOgGhQhWhgijVr9KyY7zN6jBJ6Yydu7i58Era2FCZnsPpaPxjV3iRQ" target="_blank"> ჩაერთე </a>
          </div> -->
        </div>
      </div>
      <div class="positions-wrapper">
        <div class="pos-item">
          <div class="key"><span class="desk">პირველი</span> <span class="mob">I</span> ადგილი</div>
          <div class="val">3 000₾</div>
        </div>
        <div class="pos-item">
          <div class="key"><span class="desk">მეორე</span> <span class="mob">II</span> ადგილი</div>
          <div class="val">1 500₾</div>
        </div>
        <div class="pos-item">
          <div class="key">ჩატენვები</div>
          <div class="val">1 000₾</div>
        </div>
        <div class="pos-item">
          <div class="key">MVP</div>
          <div class="val">500₾</div>
        </div>
      </div>
    </div>
    <!-- <div class="learn-more hide-mobile">
      <a href="https://play.fiba3x3.com/events/55aaec26-60ba-4405-b149-ba3d4719f5f6?fbclid=IwAR322QOgGhQhWhgijVr9KyY7zN6jBJ6Yydu7i58Era2FCZnsPpaPxjV3iRQ" target="_blank"> ჩაერთე </a>
    </div> -->
  </div>
  <a href="https://europebet.com" target="_blank" class="eu-logo">
    <span></span>
  </a>
  <div class="done-stadiums updated">
    <div class="eb-container">
      <h2 class="title-image">
        <span></span>
      </h2>
      <lightgallery
        :onAfterOpen="lgOpened"
        :onAfterClose="lgClosed"
        :settings="{
          speed: 0,
          plugins: plugins,
          enableDrag: false,
          enableThumbDrag: true,
          enableThumbSwipe: true,
          loop: true,
          enableTouch: true,
          mobileSettings: {
            controls: true,
            showCloseIcon: true,
          },
        }"
      >
        <div
          data-lg-size="1406-1390"
          class="gallery-item extra-item"
          :data-src="doneProjectImages.digomiStadium"
          data-sub-html='<div class="text-side">
            <div class="location">
            თბილისი 
            </div>
            <div class="address">
            დიღმის მასივი
            </div>
            <p>
              ბელიაშვილის ქუჩაზე მდებარე საკალათბურთო მოედნი გამეზამ მოხატა.

              “ხელოვნება და სპორტი - ეს ორი ისეთი სფეროა, რომლებითაც ყოველდღიურად ვარ დაკავებული, ხოლო მომენტი, როცა მათი გადაკვეთა ხდება - ზეიმის ტოლფასია. მოედანი, რომელიც “ნიკოსთან” ერთად მოვხატე, გარდა ესთეტიკური მნიშვნელობისა, პოზიტიური მუხტის მიმცემია ახალგაზრდებისთვის და ბავშვებისთვის, რომლებიც მოედანზე ბევრ დროს ატარებენ. მოედანი, როგორც ჯანსაღი ცხოვრების განუყოფელი დეტალი, ახლა უკვე კრეატივის და შემოქმედების ინსპირაციაც იქნება.”
              
            </p>
            </div>'
        >
          <div class="img-item img-responsive" :style="{ backgroundImage: `url(${doneProjectImages.locationImage1})` }">
            <div class="plus-btn"></div>
          </div>
          <div class="text-side">
            <div class="location">თბილისი</div>
            <div class="address">დიღმის მასივი</div>
          </div>
        </div>
        <div
          data-lg-size="1406-1390"
          class="gallery-item"
          :data-src="doneProjectImages.didubeStadium"
          data-sub-html='<div class="text-side">
      <div class="location">
      თბილისი 
      </div>
      <div class="address">
        დიდუბე
      </div>
      <p>“ჩამო რაა!” სტადიონი გოგა ქაცარავამ მოხატა
        “ნახატის შესრულების დროს ძალიან დიდი სიფრთხილე იყო საჭირო. ეს ნამუშავარი ყოველდღე ვიღაცამ უნდა ნახოს - სეირნობის დროს, თამაშის დროს, კორპუსიდან გადმოხედვის დროს და მაქსიმალურად ვეცადე, რომ ყველასთვის მორგებული ვყოფილიყავი. უბრალოდ დავწერეთ - “ჩამო, რა!“, რასაც ახსნაც არ სჭირდება, რადგან ძალიან სწორ გზავნილს იძლევა, თამაში და სპორტული აქტივობა ამ ფრაზით იწყება. ჩემთვის, ამ პროექტზე მუშაობა დიდი გამოწვევა იყო, რადგან ვერ ხედავ, რას ხატავ, თუ, რა თქმა უნდა, დრონი არ ააფრინე. ძალიან საინტერესო პროცესი იყო. საბოლოო შედეგამდე 10 დღე დაგვჭირდა, ორი დამხმარე მყავდა და ზუსტად ესმოდათ, რისი გაკეთებაც მინდოდა“. 
       </p>
      </div>'
        >
          <div class="img-item img-responsive" :style="{ backgroundImage: `url(${doneProjectImages.locationImage2})` }">
            <div class="plus-btn"></div>
          </div>
          <div class="text-side">
            <div class="location">თბილისი</div>
            <div class="address">დიდუბე</div>
          </div>
        </div>
        <div
          data-lg-size="1406-1390"
          class="gallery-item"
          :data-src="doneProjectImages.vazisubaniSquare"
          data-sub-html='<div class="text-side">
          <div class="location">
          თბილისი 
          </div>
          <div class="address">
          ვაზისუბანი
          </div>
          <p>
          პირველი სტადიონი თბილისში, ვაზისუბანში, სანდრო ციცქიშვილმა (ციცქო) მოხატა - პორტრეტი, რომელიც სანდროს და დანტეს კოლაბორაციული ხატვის დროს შეიქმნა. ხატვის პროცესი დაახლოებით 2 კვირა გაგრძელდა, რადგან ესკიზი სტადიონზე ტექნიკურად საკმაოდ რთული გადასატანი იყო.               
          </p>
          </div>'
        >
          <div class="img-item img-responsive" :style="{ backgroundImage: `url(${doneProjectImages.locationImage3})` }">
            <div class="plus-btn"></div>
          </div>
          <div class="text-side">
            <div class="location">თბილისი</div>
            <div class="address">ვაზისუბანი</div>
          </div>
        </div>
        <div
          data-lg-size="1406-1390"
          class="gallery-item"
          :data-src="doneProjectImages.kutaisiSquare"
          data-sub-html='<div class="text-side">
        <div class="location">
        ქუთაისი 
        </div>
        <div class="address">
        ჯავახიშვილის ქუჩა
        </div>
        <p>
        მეორე სტადიონზე ავსტრიელმა მხატვარმა მანუელ სკირლმა იმუშავა. არტისტს სამუშაოების შესასრულებლად 3 დღე დასჭირდა. საერთო ჯამში, ქუთაისში 220 ლიტრი საღებავი გამოიყენეს. მხატვარმა ესკიზის შესრულებისას თავისი კლასიკური სტილი გამოიყენა.
        </p>
        </div>'
        >
          <div class="img-item img-responsive" :style="{ backgroundImage: `url(${doneProjectImages.locationImage4})` }">
            <div class="plus-btn"></div>
          </div>
          <div class="text-side">
            <div class="location">ქუთაისი</div>
            <div class="address">ჯავახიშვილის ქუჩა</div>
          </div>
        </div>
        <div
          data-lg-size="1406-1390"
          class="gallery-item"
          :data-src="doneProjectImages.batumiSquare"
          data-sub-html='<div class="text-side">
        <div class="location">
        ბათუმი 
        </div>
        <div class="address">
        რუსთაველის გამზირი
        </div>
        <p>
        გერმანელმა მხატვარმა კრისტიან ჰინცმა, იგივე "კერამ", პროექტის ფარგლებში მესამე სტადიონი ქალაქ ბათუმში მოხატა. ნამუშევარი აბსტრაქტული და გეომეტრიულია. "კერამ" ბათუმის მოედნის მოხატვისას 208 კვადრატულ მეტრზე 165 ლიტრი საღებავი გამოიყენა.         
        </p>
        </div>'
        >
          <div class="img-item img-responsive" :style="{ backgroundImage: `url(${doneProjectImages.locationImage5})` }">
            <div class="plus-btn"></div>
          </div>
          <div class="text-side">
            <div class="location">ბათუმი</div>
            <div class="address">რუსთაველის გამზირი</div>
          </div>
        </div>
        <div
          data-lg-size="1406-1390"
          class="gallery-item"
          :data-src="doneProjectImages.vajaSquare"
          data-sub-html='<div class="text-side">
<div class="location">
თბილისი
</div>
<div class="address">
  ვაჟა - ფშაველას გამზირი
</div>
<p>
  მეოთხე სტადიონი ვაჟა-ფშაველას პირველ კვარტალში მოიხატა. პროექტზე მხატვარმა დათო მჭედლიშვილმა (იგივე “დანტე“) იმუშავა. სიცხისა და წვიმების გამო პროცესი გაიწელა და 1 თვე გაგრძელდა.
  
</p>
</div>'
        >
          <div class="img-item img-responsive" :style="{ backgroundImage: `url(${doneProjectImages.locationImage6})` }">
            <div class="plus-btn"></div>
          </div>
          <div class="text-side">
            <div class="location">თბილისი</div>
            <div class="address">ვაჟა - ფშაველას გამზირი</div>
          </div>
        </div>
        <div
          data-lg-size="1406-1390"
          class="gallery-item extra-item"
          :data-src="doneProjectImages.gldaniStadium"
          data-sub-html='<div class="text-side">
        <div class="location">
        თბილისი 
        </div>
        <div class="address">
         გლდანი V მიკრო
        </div>
        <p>
          მეხუთე სტადიონი გიორგი კალანდარიშვილმა, იგივე "პაბლომ" მოხატა. გიგანტური მურალის შესაქმნელად ავტორს 150 ლიტრი საღებავი და 1 კვირა დასჭირდა. ხატვის პროცესში, გამოკითხვაში გამარჯვებით გახარებული, მთელი უბანი იყო ჩართული.
          საბოლოოდ შეიქმნა ნახატი, რომელსაც კალათბურთის მოყვარულთათვის განსაკუთრებული დატვირთვა აქვს. მასზე სპორტის ამ სახობის ორი ქართველი ლეგენდა, ზურაბ საკანდელიძე და მიხეილ ქორქია არიან გამოსახული. ქორქია და საკანდელიძე როგორც მოედანზე, ისე ცხოვრებაში, განუყრელი მეგობრები იყვნენ. ქუთაისელმა კალათბურთელებმა თბილისის “დინამოში” ერთად თითქმის 15 წელი ითამაშეს. რაც მთავარია, მათ ასევე ერთად შეძლეს განსაკუთრებული ტიტულის, ოლიმპიური ჩემპიონობის მოპოვება - 1972 წლის მიუნხენის ზაფხულის თამაშების ფინალში აშშ-ის ნაკრების დამარცხების შემდეგ.
          
        </p>
        </div>'
        >
          <div class="img-item img-responsive" :style="{ backgroundImage: `url(${doneProjectImages.locationImage7})` }">
            <div class="plus-btn"></div>
          </div>
          <div class="text-side">
            <div class="location">თბილისი</div>
            <div class="address">გლდანი V მიკრო</div>
          </div>
        </div>
      </lightgallery>
    </div>
  </div>

  <div class="description-wrapper">
    <div class="eb-container">
      <div class="desc-content">
        <h3>
          თუ თამაშობ კალათბურთს, შეგიძლია <br />
          შენს გუნდთან ერთად ჩაერთო <span>3x3 </span>სერიებში
        </h3>

        <h5>როგორ დარეგისტრირდე?</h5>
        <ul>
          <li>გუნდის ყველა წევრი უნდა იყოს 18+</li>
          <li>ყველა მოთამაშე უნდა დარეგისტრირდეს play.fiba3x3.com-ზე</li>
          <li>გუნდის კაპიტანი რეგისტრირდება 3x3სერიების ტურნირზე</li>
          <li>კაპიტანი ავსებს Fiba-ს მიერ მოთხოვნილ საკონტაქტო ინფორმაციას</li>
          <li>კაპიტანი ამატებს გუნდის წევრებს (ზუსტად ისე როგორც დარეგისტრირდნენ საიტზე)</li>
        </ul>
        <p>
          დამატებითი ინფორმაციისთვის შეგიძლია შემოგვიერთდე <a href="https://www.facebook.com/groups/403717419766388" target="_blank">facebook ჯგუფში</a> ან მოგვწერო
          <a href="https://m.me/europebet" target="_blank">messenger</a>-ში.
        </p>
      </div>
    </div>
  </div>
  <!-- <span class="mobile-bg"></span> -->
</template>

<script>
import Lightgallery from "lightgallery/vue";
import lgZoom from "lightgallery/plugins/zoom";

//Done projects images
import kutaisiSquare from "./assets/img/Kutaisi_Square.png";
import kutaisiWide from "./assets/img/Kutaisi_Wide.png";

import batumiSquare from "./assets/img/Batumi_Square.png";
import batumiWide from "./assets/img/batumi_Wide.jpg";

import vajaSquare from "./assets/img/Vaja_Square.png";
import vajaWide from "./assets/img/Vaja_Wide.jpg";

import gldaniStadium from "./assets/img/gldaniStadium.png";
import digomiStadium from "./assets/img/digomiStadium.png";
import didubeStadium from "./assets/img/didubeStadium.png";

import vazisubaniSquare from "./assets/img/Vazisubani_Square.png";
import vazisubaniWide from "./assets/img/Vazisubani_Wide.jpg";
import locationImage1 from "./assets/img/locationImage1.png";
import locationImage2 from "./assets/img/locationImage2.png";
import locationImage3 from "./assets/img/locationImage3.png";
import locationImage4 from "./assets/img/locationImage4.png";
import locationImage5 from "./assets/img/locationImage5.png";
import locationImage6 from "./assets/img/locationImage6.png";
import locationImage7 from "./assets/img/locationImage7.png";

// import batumiSquare2 from "./assets/img/Vazisubani_Square.png"
import batumiWide2 from "./assets/img/soon.jpg";

import comming from "./assets/img/comming.jpg";

export default {
  name: "App",
  data: function() {
    return {
      doneProjectImages: {
        kutaisiSquare,
        kutaisiWide,
        batumiSquare,
        gldaniStadium,
        digomiStadium,
        didubeStadium,
        locationImage1,
        locationImage2,
        locationImage3,
        locationImage4,
        locationImage5,
        locationImage6,
        locationImage7,
        batumiWide,
        vajaSquare,
        vajaWide,
        vazisubaniSquare,
        vazisubaniWide,
        batumiWide2,
        comming,
      },
      plugins: [lgZoom],
    };
  },
  created() {
    console.log = function() {};
  },
  components: {
    Lightgallery,
  },
  watch: {},
  mounted() {},
  methods: {
    lgOpened: function() {
      document.querySelector("body").style.overflow = "hidden";
    },
    lgClosed: function() {
      document.querySelector("body").style.overflow = "auto";
    },
  },
};
</script>

<style>
@import "./assets/styles/reset.css";
@import "./assets/styles/style.css";
@import url("https://cdn.jsdelivr.net/npm/lightgallery@2.1.0-beta.1/css/lightgallery.css");
@import url("https://cdn.jsdelivr.net/npm/lightgallery@2.1.0-beta.1/css/lg-zoom.css");
</style>
